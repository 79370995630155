// Modules
import React, { useState, useEffect } from "react";

import { BrowserRouter as Router, Switch, Route, Redirect, useLocation, withRouter } from 'react-router-dom'

import { createClient } from '@supabase/supabase-js'
import axios from "axios"
import moment from "moment";
import TestHeader from "../global/TestHeader";


require('dotenv').config()
const {
	REACT_APP_SUPABASE_URL,
	REACT_APP_SUPABASE_SERVICE_API_KEY
} = process.env;


export const supabase = createClient(REACT_APP_SUPABASE_URL, REACT_APP_SUPABASE_SERVICE_API_KEY)


const VanModelModifiers = () => {
    const [loading, setLoading] = useState(true)
    const [saving, setSaving] = useState(false)
    const [modifierData, setModifierData] = useState(null)

    useEffect(() => {
        getSupaBaseData()
    }, [])

    async function getSupaBaseData() {
        try {
            setLoading(true)

            let { data: individualCapModifiers, error } = await supabase
                .from('van-model-modifiers')
                .select('*')
                .order('Spreadsheet Col Reference', { ascending: true })
            
            setModifierData(individualCapModifiers)
        } catch (error) {
            alert(error.message)
        } finally {
            setLoading(false)
        }
    }

    async function updateSupaBaseData(e, id) {
        e.preventDefault();

        try {
          setSaving(true)

          let { error } = await supabase.from('van-model-modifiers').upsert({
            id: id,
            modifier_values: e.target.elements.modifier_values.value,
            never_scrap: e.target.elements.never_scrap.checked,
          }, {
            returning: 'minimal', // Don't return the value after inserting
          })
    
          if (error) {
            throw error
          }
        } catch (error) {
          alert(error.message)
        } finally {
            alert("modifier updated")
          setSaving(false)
        }
    }

    return (
        <>
        <TestHeader />
        <div className="w-11/12 h-full mx-auto flex flex-col items-start justify-between pt-6 lg:pt-11">
            <div className="pb-20">
                <p className="text-md pb-6">Van Model Modifiers</p>
                <p>You can use this page to view/edit the multipliers to apply to the valuation based on specific van models:</p>
                <div className="hidden lg:flex justify-between py-10">
                    <p className="w-6/12">Spreadsheet ref / Title</p>
                    <p className="w-1/12 text-center">%</p>
                    <p className="w-2/12">&nbsp;</p>

                </div>
                {
                    (!loading) ?
                            Object.keys(modifierData).map((key, i) => {
                                return (
                                    <form key={key} action="" className="flex flex-wrap justify-between py-10 lg:py-2" onSubmit={ (e) => { updateSupaBaseData(e, modifierData[key]['id']) } } >
                                        <p className="lg:w-6/12 pb-2 lg:pb-0"><span className="hidden lg:inline-block">i{ modifierData[key]['Spreadsheet Col Reference'] }&nbsp;:&nbsp;</span>{ modifierData[key]['Name'] }</p>
                                        <input className="w-3/12 lg:w-1/12 border-2 px-2 text-center" type="text" name="modifier_values" defaultValue={modifierData[key]['modifier_values']} />
                                        <label htmlFor={`scrap_${key}`}>Never scrap?
                                            <input className="w-3/12 lg:w-1/12 border-2 px-2 text-center ml-3.5" type="checkbox" id={`scrap_${key}`} name="never_scrap" defaultChecked={modifierData[key]['never_scrap']} />
                                        </label>
                                        <div className="w-2/12">
                                            <button className="bg-black text-white px-2 py-1 block" type="submit" disabled={ (saving) ? true : '' }>Save</button>
                                        </div>
                                        
                                    </form>
                                )
                            })
                    : <p>Loading</p>
                }
            </div>
        </div>
    </>
    );
};

export default withRouter(VanModelModifiers);