import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

// Components
import MileageSlider from "../form/MileageSlider";
import ExpectedValueSlider from "../form/ExpectedValueSlider";
import ContactLink from "../global/ContactLink";

// Variables
const title = "Tell us more about your van";

const container = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      ease: [0.43, 0.13, 0.23, 0.96],
      duration: 0.8,
      staggerChildren: 0.15,
      delayChildren: 0,
    },
  },
};

const item = {
  hidden: {
    y: 30,
    opacity: 0,
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.43, 0.13, 0.23, 0.96],
      duration: 0.6,
    },
  },
};

const minorMechanical = [
  "Limp mode/check engine light",
  "Battery/Alternator/Starter motor",
  "Smoking",
  "Suspension/steering/breaks",
  "Differential",
  "Emissions/DPF/AdBlue"
]
const majorMechanical = [
  "Head gasket",
  "Timing chain/cambelt",
  "Knocking/seized",
  "Low compression",
  "Turbo",
  "Overheating/coolant system",
  "Structural rust/corrosion",
  "Gearbox",
  "Clutch/flywheel",
  "Fuel system/injectors/pump"
]

const mechanicalProblemsObject = [
  {
    "label": "Head gasket",
    "type": "major",
  },{
    "label": "Timing chain/cambelt",
    "type": "major",
  },{
    "label": "Knocking/seized",
    "type": "major",
  },{
    "label": "Limp mode/check engine light",
    "type": "minor",
  },{
    "label": "Battery/Alternator/Starter motor",
    "type": "minor",
  },{
    "label": "Smoking",
    "type": "minor",
  },{
    "label": "Turbo",
    "type": "major",
  },{
    "label": "Overheating/coolant system",
    "type": "major",
  },{
    "label": "Suspension/steering/breaks",
    "type": "minor",
  },{
    "label": "Structural rust/corrosion",
    "type": "major",
  },{
    "label": "Gearbox",
    "type": "major",
  },{
    "label": "Clutch/flywheel",
    "type": "major",
  },{
    "label": "Differential",
    "type": "minor",
  },{
    "label": "Fuel system/injectors/pump",
    "type": "major",
  },{
    "label": "Emissions/DPF/AdBlue",
    "type": "minor",
  },
]




const Stage1 = ({ completeFormStage, goPreviousStage, formData, inputChanged, sliderChanged, mechanicalProblemsChanged, callingAPI }) => {
  const [mechanicalStatus, setMechanicalStatus] = useState("perfect");
  const [expectedValueMessage, setExpectedValueMessage] = useState("");
  const [mileageMessage, setMileageMessage] = useState("");

  const mechanicalChanged = event => {
    event.persist();
    setMechanicalStatus(event.target.value)
    
    inputChanged(event)
  };

  const validateFields = (e) => {
    e.preventDefault();
    let isError = false
  
    if(formData.expected == "") {
      setExpectedValueMessage("Please enter an expected value for the vehicle")
      isError = true
    } else {
      setExpectedValueMessage("")
    }

    if(formData.mileage == "") {
      setMileageMessage("Please enter an approximate mileage for the vehicle")
      isError = true
    } else {
      setMileageMessage("")

    }

    if(isError) {
      return
    }
  
    completeFormStage(e)
  
  }
  
  return (
    <>
      <motion.article
        variants={container}
        initial="hidden"
        animate="show"
        className="relative lg:h-full lg:w-6/12 pt-10 lg:pt-14 pb-12 flex flex-col justify-between z-10"
      >
        {/* {callingAPI && (
            <div className="loading"><div className="loading-indicator"><div></div><div></div><div></div><div></div></div></div>
          )} */}
        <div>
          <motion.h3 variants={item} className="font-body text-md leading-none hidden lg:block">
            {title}
          </motion.h3>
          <motion.div
            variants={item}
            className="max-w-3xl grid grid-cols-1 lg:grid-cols-4 gap-3 lg:gap-5 pt-7"
          >
            <div className="col-span-4">
              <label className="p-3 font-body text-base text-black text-opacity-50 leading-none w-full block" htmlFor="mileage">Mileage</label>
              <input
                type="text"
                id="mileage"
                name="mileage"
                defaultValue={formData.mileage}
                onChange={inputChanged} 
                className="border-blue border focus:outline-none focus:ring-0 w-full h-14 rounded-md px-5 text-base font-body"
              />
              { (mileageMessage) ?
                    <p className="font-body text-xs mt-2 px-3 text-orange">{ mileageMessage }</p>
                  : ''}
            </div>

            <div className="col-span-4 lg:col-span-2">
              <label className="p-3 font-body text-base text-black text-opacity-50 leading-none w-full block" htmlFor="vanType">Type of van</label>
              <select
                name="vanType"
                id="vanType"
                className="border-blue border outline-none focus:outline-none focus:ring-0 h-14 rounded-md px-3 text-base font-body text-opacity-50 leading-none block w-full"
                onChange={inputChanged} 
                defaultValue={formData.vanType}
              >
                <option value="Panel Van">Panel van</option>
                <option value="4x4, Jeep or Pickup">4x4, Jeep or Pickup</option>
                <option value="Camper">Camper</option>
                <option value="Tipper">Tipper</option>
                <option value="Chassis Cab">Chassis Cab</option>
                <option value="Flatbed">Flatbed</option>
                <option value="Crew Cab">Crew Cab</option>
                <option value="Luton">Luton</option>
                <option value="Motorhome">Motorhome</option>
                <option value="Cherry Picker">Cherry Picker</option>
                <option value="Minibus">Minibus</option>
              </select>
            </div>

            <div className="col-span-4 lg:col-span-2">
              <label className="p-3 font-body text-base text-black text-opacity-50 leading-none w-full block" htmlFor="bodywork">Bodywork condition</label>

              <select
                name="bodywork"
                id="bodywork"
                onChange={inputChanged}
                defaultValue={formData.bodywork}
                className="border-blue border outline-none focus:outline-none focus:ring-0 w-full h-14 rounded-md px-3 text-base font-body leading-none"
              >
                <option defaultValue value="clean">Perfect</option>
                <option value="average">Average</option>
                <option value="poor">Poor</option>
              </select>

            </div>


            <div className="col-span-4 lg:col-span-3">
              <label className="p-3 font-body text-base text-black text-opacity-50 leading-none w-full block" htmlFor="customerMechanical">Mechanical condition</label>

              <select
                name="customerMechanical"
                id="customerMechanical"
                onChange={mechanicalChanged}
                defaultValue={formData.customerMechanical}
                className="border-blue border outline-none focus:outline-none focus:ring-0 w-full h-14 rounded-md px-3 text-base font-body leading-none"
              >
                <option defaultValue value="perfect">Perfect</option>
                <option value="minor">Minor issues</option>
                <option value="major">Major issues</option>
              </select>

            </div>


            <div className={`col-span-4 lg:col-span-4${(mechanicalStatus != "perfect") ? '' : ' hidden' }`}>
              
              <div className="max-w-3xl grid grid-cols-1 lg:grid-cols-4 gap-3 lg:gap-5 pt-4 pb-4">
                {
                    mechanicalProblemsObject &&
                    mechanicalProblemsObject.map((name, index) => (
                      <div key={mechanicalProblemsObject[index]['label']} className={`${(mechanicalStatus != "perfect") ? 'block' : 'hidden'} col-span-4 lg:col-span-2`}>
                        <input
                          className="form-checkbox"
                          onChange={mechanicalProblemsChanged}
                          type="checkbox"
                          value={mechanicalProblemsObject[index]['label']}
                          id={mechanicalProblemsObject[index]['label']}
                          name={`${mechanicalProblemsObject[index]['type']}Mechanical`}
                        />
                        <label className="pl-4" htmlFor={mechanicalProblemsObject[index]['label']}>
                          {mechanicalProblemsObject[index]['label']}
                        </label>
                      </div>
                    ))
                }
              </div>
              
            </div>

            


            <div className="col-span-4 mb-4">
              <label className="p-3 font-body text-base text-black text-opacity-50 leading-none w-full block" htmlFor="expected">Expected value</label>
              <div className="pound-form-wrapper">
              <input
                type="text"
                id="expected"
                name="expected"
                defaultValue={formData.expected}
                onChange={inputChanged} 
                className="border-blue border focus:outline-none focus:ring-0 w-full h-14 rounded-md px-5 text-base font-body"
              /></div>
              { (expectedValueMessage) ?
                    <p className="font-body text-xs mt-2 px-3 text-orange">{ expectedValueMessage }</p>
                  : ''}
            </div>
            
            <div className="flex justify-between col-span-4">
              <button
                disabled={callingAPI}
                onClick={goPreviousStage}
                className="bg-teal h-14 rounded-md text-base font-body w-3/12 text-white mr-2 lg:mr-12"
              >
                Previous<span className="hidden md:inline"> step</span>
              </button>
              <button
                disabled={callingAPI}
                // onClick={completeFormStage}
                onClick={validateFields}
                className="bg-blue h-14 rounded-md text-base font-body w-9/12"
              >
                <span className="font-bold">Next</span> - Your details
              </button>
            </div>
            
          </motion.div>
        </div>
        <motion.div variants={item}>
          <ContactLink />
        </motion.div>
      </motion.article>
    </>
  );
};

export default Stage1;
