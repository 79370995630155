// Modules
import React, { useState, useEffect } from "react";

import { BrowserRouter as Router, Switch, Route, Redirect, useLocation, withRouter } from 'react-router-dom'


import axios from "axios"
import moment from "moment"
import TestHeader from "../global/TestHeader"


const ValuationTest = () => {
    const [loading, setLoading] = useState(false)
    const [valuationData, setValuationData] = useState(null)

    async function getValuation(e) {
        e.preventDefault();
        try {
            setLoading(true)
            setValuationData(null)

            let formData = {
                "vrm": e.target.vrm.value,
                // "dateOfReg": e.target.dateOfReg.value,
                "mechanicalProblems": e.target.mechanicalProblems.value,
                "mileage": e.target.mileage.value,
                "bodywork": e.target.bodywork.value,
                "vanType": e.target.vanType.value,
                "debug": true
            }
            
            axios.post('/.netlify/functions/vehicle-details?reg='+e.target.vrm.value).then((response) => {
                let vehicleDetails = response.data
                if(!vehicleDetails['success']) {
                    return
                }
                // formData["dateOfReg"] = vehicleDetails['year']
                formData["vehicleDetails"] = vehicleDetails
                console.log(formData)
                axios({
                    method: 'post',
                    url: '/.netlify/functions/valuation-check',
                    headers: { "Content-Type": "application/json" },
                    data: JSON.stringify({
                        ...formData
                    })
                }).then((response) => {
                    setValuationData(response['data'])
                    console.log(valuationData)
                })

            }).catch(err => console.log(err));

            

        } catch (error) {
            alert(error.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
        <TestHeader />
        <div className="w-11/12 h-full mx-auto flex flex-col items-start justify-between pt-6 lg:pt-11">
            <div className="pb-20 w-full">
                <p className="text-md pb-6">Valuation Test</p>
                <p>You can use this page to test the algorithm by sending through fake vehicle details using the form below. It will return the correct valuation, if required.</p>
                
                <form action="" className="py-10 lg:py-2 flex flex-wrap" onSubmit={ (e) => { getValuation(e) } } >
                    
                    <div className="md:w-6/12 px-10">
                        <div className="py-4">
                            <label htmlFor="vrm" className="block mb-2">VRM (reg)</label>
                            <input type="text" name="vrm" required defaultValue="HX63YYB" className="border-2 p-2 w-full" />
                        </div>
                        {/* <div className="py-4">
                            <label htmlFor="dateOfReg" className="block mb-2">Date of reg (year)</label>
                            <input type="text" name="dateOfReg" required minlength="4" maxlength="4" placeholder="2020" className="border-2 p-2 w-full" />
                        </div> */}
                        <div className="py-4">
                            <label htmlFor="mechanicalProblems" className="block mb-2">Mechanical Problems</label>
                            <select required defaultValue="perfect" name="mechanicalProblems" className="border-2 p-2 w-full">
                                <option value="perfect">Perfect</option>
                                <option value="major">Major (or 3 minors)</option>
                                <option value="minor">Minor</option>
                            </select>
                        </div>
                        <div className="py-4">
                            <label htmlFor="mileage" className="block mb-2">Mileage</label>
                            <input required type="text" name="mileage" minlength="4" maxlength="10" placeholder="100000" defaultValue="10000" className="border-2 p-2 w-full" />
                        </div>
                    
                    </div>

                    <div className="md:w-6/12 px-10">
                        <div className="py-4">
                            <label htmlFor="bodywork" className="block mb-2">Bodywork</label>
                            <select required defaultValue="clean" name="bodywork" className="border-2 p-2 w-full">
                                <option value="clean">Clean</option>
                                <option value="average">Average</option>
                                <option value="poor">Poor</option>
                            </select>
                        </div>
                        
                        <div className="py-4">
                            <label htmlFor="vanType" className="block mb-2">Van type</label>
                            <select required name="vanType" className="border-2 p-2 w-full">
                                <option value="Panel Van">Panel Van</option>
                                <option value="Camper">Camper</option>
                                <option value="4x4, Jeep or Pickup">4x4, Jeep or Pickup</option>
                                <option value="Tipper">Tipper</option>
                                <option value="Chassis Cab">Chassis Cab</option>
                                <option value="Flatbed">Flatbed</option>
                                <option value="Crew Cab">Crew Cab</option>
                                <option value="Luton">Luton</option>
                                <option value="Motorhome">Motorhome</option>
                                <option value="Cherry Picker">Cherry Picker</option>
                                <option value="Minibus">Minibus</option>
                            </select>
                        </div>

                    </div>
                    <div className="w-full px-10 text-right">
                        <button className="bg-black text-white px-4 py-2 block mt-4" type="submit" disabled={ (loading) ? true : '' }>Submit</button>
                    </div>
                    

                </form>

                {
                    (!loading && valuationData) ?
                        (
                            <div className="w-10/12 p-12">
                                <p className="pb-6">Valuation info - Spreadsheet column reference: <strong>{valuationData['content']['algorithmInfo']['spreadSheetKeyRef']}</strong></p>
                                {
                                    (valuationData['success'] && valuationData['content']['valuation']) ?
                                        (
                                            <div>
                                                <p className="text-lg pb-6">{valuationData['content']['message']}: <strong>£{valuationData['content']['valuation']}</strong></p>
                                                <p>Initial CAP valution: <strong>£{valuationData['content']['algorithmInfo']['initialCapValue']}</strong></p>
                                                <p>CAP trade value used: <strong>{valuationData['content']['algorithmInfo']['capValueUsed']}</strong></p>
                                                <p>Modifier: <strong>{valuationData['content']['algorithmInfo']['modifier']}</strong></p>
                                                <p>Van type modifier ({valuationData['content']['algorithmInfo']['vanTypeModifier']['vanType']}): <strong>{valuationData['content']['algorithmInfo']['vanTypeModifier']['modifyBy']}</strong></p>
                                                <p>Model modifier ({valuationData['content']['algorithmInfo']['vanModelModifier']['model']}): <strong>{valuationData['content']['algorithmInfo']['vanModelModifier']['modifyBy']}</strong></p>
                                            </div>
                                        )
                                    : ''
                                }

                                {
                                    (valuationData['success'] && !valuationData['content']['valuation']) ?
                                        (
                                            <p className="text-md">No call to CAP has been made, this message will be shown: <br/><strong>{valuationData['content']['message']}</strong></p>
                                        )
                                    : ''
                                }
                                

                                <p className="pt-10">Full response data:</p>
                                <code className="whitespace-pre break-words bg-blue-light w-11/12 overflow-scroll block py-10 px-6">{JSON.stringify(valuationData, null, "\t")}</code>
                            </div>
                        )
                    : ''
                }
            </div>
        </div>
        </>
    );
};

export default withRouter(ValuationTest);