import React from "react";

function Header() {
  return (
    <header className="w-full pt-5 lg:pt-6 px-6 lg:px-4 xl:px-8 mb-auto">
      <nav className="flex items-center justify-between">
        <img style={{
            maxWidth: "11rem",
            minWidth: "0",

        }} src="https://wbbv.persona.studio/wp-content/themes/wbbv/dist/img/logo.gif" />
        <a href="//webuybrokenvans.co.uk">
          <div className="bg-yellow px-4 lg:px-14 py-2 lg:py-4 rounded-md font-body">
            Return to site
          </div>
        </a>
      </nav>
    </header>
  );
}

export default Header;
