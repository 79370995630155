import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import ContactLink from "../global/ContactLink";


import axios from "axios"

const title = "Confirmation";

const confirmDesc =
  "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum.";

const container = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      ease: [0.43, 0.13, 0.23, 0.96],
      duration: 0.8,
      staggerChildren: 0.15,
      delayChildren: 0,
    },
  },
};

const item = {
  hidden: {
    y: 30,
    opacity: 0,
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.43, 0.13, 0.23, 0.96],
      duration: 0.6,
    },
  },
};

const Stage4 = ({ completeFormStage, goPreviousStage, formData, callingAPI }) => {

  
  return (
    <>
      <motion.article
        variants={container}
        initial="hidden"
        animate="show"
        className="relative lg:h-full lg:w-9/12 pb-10 flex flex-col justify-between z-10 m-auto"
      >
        {/* {callingAPI && (
            <div className="loading"><div className="loading-indicator"><div></div><div></div><div></div><div></div></div></div>
          )} */}
        <div>
          <motion.div
            variants={item}
            className="max-w-3xl grid grid-cols-1 gap-0"
          >
            
            <div className="video-wrapper">

            <iframe src="https://player.vimeo.com/video/655368614?h=bcb2a8ca69&amp;title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;background=1" width="3840" height="2160" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="wbbv-thank-you"></iframe>

            </div>
            
            <div className="grid-span-1 pt-10 text-center">
              <p>To help with the process of selling your van you can provide additional images/details of your vehicle using the below links</p>
              <a href="https://wa.me/447582859859" className="bg-yellow w-60 h-14 rounded-md mt-5 text-base font-body inline-flex items-center justify-center mx-2">Send via WhatsApp</a>
              <a href="mailto:sales@webuybrokenvans.co.uk" className="bg-blue w-60 h-14 rounded-md mt-5 text-base font-body inline-flex items-center justify-center mx-2">Send via e-mail</a>

            </div>
          </motion.div>
        </div>
      </motion.article>
    </>
  );
};

export default Stage4;
