import React from "react";
import { motion } from "framer-motion";
import TransitVan from "../../assets/images/transit-van@2x.png";
import moment from "moment";

// Variables
const vanDetails = [
  {
    infoType: "2016",
    infoID: 1,
  },
  {
    infoType: "Silver",
    infoID: 2,
  },
  {
    infoType: "Diesel",
    infoID: 3,
  },
  {
    infoType: "55000 miles",
    infoID: 4,
  },
];

const QuoteInfo1 = ({formStepTitles, formStage, vehicleDetails, vehicleValuation, vehicleValuationMessage, formData}) => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          ease: [0.43, 0.13, 0.23, 0.96],
          duration: 0.6,
          delay: 0.2,
        }}
        className={`lg:w-6/12 py-4 flex flex-col items-center justify-start lg:-mt-20${(formStage > 3 && vehicleValuationMessage) ? ' -mb-64 mb-0' : ''}`}
      >

        <h3 className="font-body text-md leading-none block lg:hidden text-center pb-6">
          { formStepTitles[formStage-2] }
        </h3>

        <div className={`pointer-events-none overflow-hidden w-full lg:w-7/12 h-64 transition transition-transform transition-opacity duration-500 ${(formStage > 3 && vehicleValuationMessage) ? 'transform -translate-y-64 opacity-0' : ''}`}>
          <img src={TransitVan} className="object-contain" />
        </div>

        
        <div className={ `transition-transform duration-500 ${(formStage > 3 && vehicleValuationMessage) ? 'transform -translate-y-64' : ''}` }>
         
          {
            (vehicleDetails.reg) ? (
              <div className="flex flex-col items-center justify-center w-72 mt-10">
                <div className="grid grid-cols-1 gap-5 w-full">
                  <h4 className="text-md text-center">{vehicleDetails.make} {vehicleDetails.model}</h4>

                  <ul className="flex justify-center">
                    <li className="font-body text-base opacity-50 px-2">{vehicleDetails.description}</li>
                    <li className="font-body text-base opacity-50 px-2">{vehicleDetails.year}</li>
                  </ul>

                  <div className="bg-yellow px-8 py-3 rounded-md">
                    <p className="uppercase text-center tracking-widest text-md font-sans font-medium">
                      {vehicleDetails.reg}
                    </p>
                  </div>
                </div>
              </div>
            ) : ''
          }
          
          
          {
            (formStage > 3 && vehicleValuation) ?
              (
                <div className="bg-grey rounded-md px-8 py-10 flex flex-col items-center mt-6">
                  <p className="font-sans text-center leading-none text-base">
                    We can pick up your van
                    <br />
                    for free and pay you
                  </p>
                  <p className="uppercase text-xl font-sans font-medium tracking-tightest mt-3">
                    £{
                      vehicleValuation
                    }
                  </p>
                </div>
              )
            : ''
          }
          
          {
            (formStage > 3 && !vehicleValuation) ?
              (
                <div className="bg-grey rounded-md px-8 py-10 mt-6 w-72">
                  <p className="font-sans text-center leading-none text-md">
                    { vehicleValuationMessage }
                  </p>
                </div>
              )
            : ''
          }
          
          
          {
            (formStage > 4 && formData) ?
              (
                <div className="bg-grey rounded-md px-8 py-10 flex flex-col items-center mt-6">
                  {
                    (formData.date && formData.time) ?
                      (
                        <>
                          <p className="font-sans text-center leading-tight text-base">
                            To be picked up on
                            <br />
                            <span className="font-bold">{moment(formData.date).format('dddd Do MMM')} at {moment(formData.time, 'hh:mm:ss').format('ha')}</span>
                          </p>
                        </>
                      )
                    : ''
                  }
                  
                </div>
              )
            : ''
          }
        </div>
        

      </motion.div>
    </>
  );
};

export default QuoteInfo1;
