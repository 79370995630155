import React from "react";
import { motion } from "framer-motion";

// Variables
const stageList = [
  {
    number: "1",
    title: "Number 1",
    url: "/",
    stageID: 1,
  },
  {
    number: "2",
    title: "Number 2",
    url: "/",
    stageID: 2,
  },
  {
    number: "3",
    title: "Number 3",
    url: "/",
    stageID: 3,
  },
  {
    number: "4",
    title: "Number 4",
    url: "/",
    stageID: 4,
  },
];

const StageIndicator = ({ formStage }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, ease: [0.43, 0.13, 0.23, 0.96] }}
      className="hidden lg:flex items-center justify-center lg:justify-start"
    >
      <ul className="w-80 flex items-center">
        {stageList.map(function (item) {
          // Set background colour based on stage number
          let currentBG = "white"; // Default white

          if (formStage > item.stageID) {
            currentBG = "blue"; // If stage is completed (previous)
          }

          if (formStage === item.stageID) {
            currentBG = "blue-light"; // If current stage
          }

          return (
            <li
              className={`indicator-list text-black transition-colors font-body border-blue bg-${currentBG} border rounded-full flex items-center justify-center h-10 w-10`}
              key={item.stageID}
            >
              {item.number}
            </li>
          );
        })}
      </ul>
    </motion.div>
  );
};

export default StageIndicator;
