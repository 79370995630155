import React, { useEffect } from "react";
import "./styles/main.scss";
import FormMaster from "./components/form/FormMaster";
import ValuationModifiers from "./components/pages/ValuationModifiers";
import VanTypeModifiers from "./components/pages/VanTypeModifiers";
import VanModelModifiers from "./components/pages/VanModelModifiers";
import ValuationTest from "./components/pages/ValuationTest";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import TagManager from 'react-gtm-module'

function App() {

  const tagManagerArgs = {
    gtmId: 'GTM-NWHP6R3'
  }

  TagManager.initialize(tagManagerArgs)

  const SITE_KEY = "6Ld-06QdAAAAAA4keEOZtn-ftbFPZPwH0ee6ePw1";

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });


  }, []);

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
   
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
   
      if (isScriptExist && callback) callback();
    }
   
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`);
    
  }, []);
  

  return (
    <Router>
      <div className="viewport-screen overflow-x-hidden flex flex-col justify-around pb-10">
        <Switch>
          <Route path="/valuation-test" exact render={(props) => <ValuationTest {...props} />}/> 
          <Route path="/valuation-modifiers" exact render={(props) => <ValuationModifiers {...props} />}/> 
          <Route path="/van-type-modifiers" exact render={(props) => <VanTypeModifiers {...props} />}/> 
          <Route path="/van-model-modifiers" exact render={(props) => <VanModelModifiers {...props} />}/> 
          <Route path="/" render={(props) => <FormMaster {...props} />}/> 
        </Switch>
      </div>
    </Router>
  );
}

export default App;
