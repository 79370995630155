// Modules
import React, { useState, useEffect } from "react";
import axios from "axios"
import moment from "moment";
import Header from "../global/Header";
// Componenets
import StageIndicator from "../stages/StageIndicator";
import StageRegNumber from "../stages/StageRegNumber";
import StageIntro from "../stages/StageIntro";
import Stage1 from "../stages/Stage1";
import Stage2 from "../stages/Stage2";
import Stage3 from "../stages/Stage3";
import Stage4 from "../stages/Stage4";
import QuoteInfo1 from "../global/QuoteInfo1";
import QuoteInfo2 from "../global/QuoteInfo2";
import QuoteInfo3 from "../global/QuoteInfo3";
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation, withRouter } from 'react-router-dom'
import { useTimer } from 'react-timer-hook';

// Variables

const FormMaster = () => {

  
  
  



  const SITE_KEY = "6Ld-06QdAAAAAA4keEOZtn-ftbFPZPwH0ee6ePw1";

  const search = useLocation().search;
  let reg = new URLSearchParams(search).get('reg')
  if(reg) {
    reg = reg.replace(/\s/g, '');
  }

  const [vehicleDetails, setVehicleDetails] = useState(false);
  const [spreadsheetIDs, setSpreadsheetIDs] = useState("");
  const [vehicleDetailLoaded, setVehicleDetailLoaded] = useState(false);
  const [vehicleValuation, setVehicleValuation] = useState(false);
  const [capValues, setCapValues] = useState({});
  const [vehicleValuationMessage, setVehicleValuationMessage] = useState("");
  const [callingAPI, setCallingAPI] = useState(false);
  const [regNumber, setRegNumber] = useState(reg);
  const [mondayCrmItemId, setMondayCrmItemId] = useState(null);
  const [formStage, setFormStage] = useState((regNumber) ? 1 : 0);
  // const [formStage, setFormStage] = useState(2);

  const formStepTitles = [
    "Tell us more about your van",
    "Your details",
    "Quote details and Collection",
    "Confirmation",
  ]

  const [minorMechanicalProblems, setMinorMechanicalProblems] = useState([]);
  const [majorMechanicalProblems, setMajorMechanicalProblems] = useState([]);
  const [mechanicalProblems, setMechanicalProblems] = useState("perfect");
  
  const [formData, setFormData] = useState({
    "vrm": regNumber,
    "mileage": "",
    "customerMechanical": "perfect",
    "mechanicalProblems": mechanicalProblems,
    "mechanicalProblemsList": minorMechanicalProblems.concat(majorMechanicalProblems),
    "bodywork": "clean",
    "vanType": "Panel Van",
    "expected": "",
    "message": "",
    "name": "",
    "email": "",
    "phone": "",
    "postcode": "",
    "date": moment().add(7, 'days').format('YYYY-MM-DD'),
    "time": "07:00:00",
    "urlParams": search,
  });



  // const time = new Date();
  // // let expiryTimestamp = time.setSeconds(time.getSeconds() + 10); // 10 seconds
  // let expiryTimestamp = time.setSeconds(time.getSeconds() + 600); // ten minute timer
  // let autoStart = false

  // const timer = useTimer({ expiryTimestamp, autoStart, onExpire: () => {
  //   console.log("updating Date")

  //   axios({
  //       method: 'post',
  //       url: '/.netlify/functions/supabase-update-date',
  //       headers: { "Content-Type": "application/json" },
  //       data: JSON.stringify({
  //         id: mondayCrmItemId
  //       })
  //   }).then((response) => {
  //       console.log(response['data'])
  //   })

  // } });

  

  const inputChanged = event => {
    event.persist();

    if(event.target.id == "expected" || event.target.id == "mileage") {
      event.target.value = event.target.value.replace(/\D/g, "");
    }

    setFormData(prev => ({
      ...prev,
      [event.target.id]: event.target.value
    }));
  };

  const mechanicalProblemsChanged = event => {
    event.persist();
    const { name, value } = event.target;
    
    if(name == "minorMechanical") {
      if (minorMechanicalProblems.includes(value)) {
        setMinorMechanicalProblems(minorMechanicalProblems.filter(problem => problem != value));
      } else {
        setMinorMechanicalProblems(minorMechanicalProblems => [...minorMechanicalProblems, value]);
      }
    }

    if(name == "majorMechanical") {
      if (majorMechanicalProblems.includes(value)) {
        setMajorMechanicalProblems(majorMechanicalProblems.filter(problem => problem != value));
      } else {
        setMajorMechanicalProblems(majorMechanicalProblems => [...majorMechanicalProblems, value]);
      }
    }
    
    
    
    
  };
  

  const sliderChanged = (name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      ["mechanicalProblems"]: mechanicalProblems
    }));
  }, [mechanicalProblems]);
  
  
  useEffect(() => {
    if(minorMechanicalProblems.length > 0 && minorMechanicalProblems.length < 3) {
      setMechanicalProblems("minor")
    }
    
    if(minorMechanicalProblems.length > 2) {
      setMechanicalProblems("major")
    }

    if(majorMechanicalProblems.length > 0) {
      setMechanicalProblems("major")
    }

    setFormData(prev => ({
      ...prev,
      ["mechanicalProblemsList"]: minorMechanicalProblems.concat(majorMechanicalProblems),
    }));

  }, [majorMechanicalProblems]);
  
  useEffect(() => {
    if(minorMechanicalProblems.length > 0 && minorMechanicalProblems.length < 3) {
      setMechanicalProblems("minor")
    }
    
    if(minorMechanicalProblems.length > 2) {
      setMechanicalProblems("major")
    }

    if(majorMechanicalProblems.length > 0) {
      setMechanicalProblems("major")
    }

    setFormData(prev => ({
      ...prev,
      ["mechanicalProblemsList"]: minorMechanicalProblems.concat(majorMechanicalProblems),
    }));


  }, [minorMechanicalProblems]);

  useEffect(() => {
    if(!vehicleDetails && regNumber && formStage === 1) {
      setCallingAPI(true)
      axios.post('/.netlify/functions/vehicle-details?reg='+regNumber).then((response) => {
        let vehicleDetails = response.data

        setCallingAPI(false)
        
        if(!vehicleDetails['success']) {
          setVehicleDetailLoaded(true)
          return
        }

        setVehicleDetails(vehicleDetails)

        setVehicleDetailLoaded(true)

        setFormData(prev => ({
          ...prev,
          ["vrm"]: regNumber,
          ["dateOfReg"]: String(vehicleDetails.year),
          ["vehicleDetails"]: vehicleDetails
        }));
        
      }).catch(err => console.log(err));
    } else {
      setCallingAPI(false)
      
      setVehicleDetails(vehicleDetails)

      setVehicleDetailLoaded(true)
      
      setFormData(prev => ({
        ...prev,
        ["vrm"]: String(vehicleDetails.reg),
        ["dateOfReg"]: String(vehicleDetails.year),
        ["vehicleDetails"]: vehicleDetails
      }));
    }
  }, [vehicleDetails]);


  useEffect(() => {
    window.addEventListener('beforeunload', setSupabaseItemToCompleted)
    return () => {
      window.removeEventListener('beforeunload', setSupabaseItemToCompleted)
    }
  }, [mondayCrmItemId])

  const setSupabaseItemToCompleted = e => {
    if(formStage == 1 || formStage == 2) {
      e.preventDefault()
      axios({
        method: 'post',
        url: '/.netlify/functions/supabase-update-item',
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify({
          id: mondayCrmItemId,
          valueID: spreadsheetIDs,
          valuation: (vehicleValuation) ? vehicleValuation : 0,
          capValues: (capValues) ? capValues : {},
          formComplete: 1,
          ...formData
        })
      }).then((response) => {

      })
    }
    
  }
  

  const completeFormStage = (e) => {
    if(e && e.type) {
      e.preventDefault();
    }

    if(formStage == 0) {
      setRegNumber(e)
      setFormData(prev => ({
        ...prev,
        ["vrm"]: e
      }));
    }
    
    setCallingAPI(true)
    
    switch(formStage) {
      case 1:
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
            
            setFormStage((cur) => cur + 1);

            axios({
                method: 'post',
                url: '/.netlify/functions/supabase-new-item',
                headers: { "Content-Type": "application/json" },
                data: JSON.stringify({
                    ...formData
                })
            }).then((response) => {
              let crmInfo = response.data
              if(!crmInfo['success']) {
                return
              }

              // Start 10 minute timer!
              // timer.start()

              setCallingAPI(false)

              setMondayCrmItemId(crmInfo['id'])

              

            }).catch((response) => {
              
            })

          });
        });

        

        break;
      case 2:

        setFormStage((cur) => cur + 1);
        
        axios({
            method: 'post',
            url: '/.netlify/functions/valuation-check',
            headers: { "Content-Type": "application/json" },
            data: JSON.stringify({
                ...formData
            })
        }).then((response) => {
            console.log(response['data'])

            if(!response['data']) {
              return
            }

            let valuationAmmount = response['data']['content']['valuation']

            let capValuesObj = {
              "capAverage": response['data']['content']['capAverage'],
              "capClean": response['data']['content']['capClean'],
              "capPoor": response['data']['content']['capPoor'],
              "capRetail": response['data']['content']['capRetail']
            }

            let valuationMessage = response['data']['content']['message']
            let valuationSpreadsheetRefs = `${response['data']['content']['i']}${(response['data']['content']['ic']) ? ' - '+response['data']['content']['ic'] : ''}${(response['data']['content']['id']) ? ' - '+response['data']['content']['id'] : ''}`;
            
            setVehicleValuation(valuationAmmount)
            setCapValues(capValuesObj)
            setVehicleValuationMessage(valuationMessage)
            setSpreadsheetIDs(valuationSpreadsheetRefs)



            axios({
                method: 'post',
                url: '/.netlify/functions/supabase-update-item',
                headers: { "Content-Type": "application/json" },
                data: JSON.stringify({
                  id: mondayCrmItemId,
                  valueID: valuationSpreadsheetRefs,
                  valuation: (valuationAmmount) ? valuationAmmount : 0,
                  capValues: (capValuesObj) ? capValuesObj : {},
                  ...formData
                })
            }).then((response) => {
                console.log(response['data'])
                setCallingAPI(false)
    
                
            }).catch((response) => {
              //
            })

            // setFormStage((cur) => cur + 1);
        }).catch((response) => {
          //
        })
        

        break;
      case 3:

        setFormStage((cur) => cur + 1);

        axios({
          method: 'post',
          url: '/.netlify/functions/supabase-update-item',
          headers: { "Content-Type": "application/json" },
          data: JSON.stringify({
            id: mondayCrmItemId,
            valueID: spreadsheetIDs,
            valuation: (vehicleValuation) ? vehicleValuation : 0,
            capValues: (capValues) ? capValues : {},
            ...formData
          })
        }).then((response) => {
          
            // create MONDAY entry, and delete supabase entry
            axios({
              method: 'post',
              url: '/.netlify/functions/crm-new-item',
              headers: { "Content-Type": "application/json" },
              data: JSON.stringify({
                valueID: spreadsheetIDs,
                valuation: (vehicleValuation) ? vehicleValuation : 0,
                capValues: (capValues) ? capValues : {},
                ...formData
              })
            }).then((response) => {
              
              // if successfully added to the CRM, delete from Supabase
              axios({
                method: 'post',
                url: '/.netlify/functions/supabase-delete-item',
                headers: { "Content-Type": "application/json" },
                data: JSON.stringify({
                  id: mondayCrmItemId,
                })
              })
    
            }).catch((err) => {

              // if monday CRM errors, set item ready to be go in Supabase
              axios({
                method: 'post',
                url: '/.netlify/functions/supabase-update-item',
                headers: { "Content-Type": "application/json" },
                data: JSON.stringify({
                  id: mondayCrmItemId,
                  valueID: spreadsheetIDs,
                  valuation: (vehicleValuation) ? vehicleValuation : 0,
                  capValues: (capValues) ? capValues : {},
                  formComplete: 1,
                  ...formData
                })
              })

            })



            setCallingAPI(false)

        }).catch((response) => {
          //
        })

        break;
      // case 4:
      //   axios({
      //     method: 'post',
      //     url: '/.netlify/functions/supabase-update-item',
      //     headers: { "Content-Type": "application/json" },
      //     data: JSON.stringify({
      //       id: mondayCrmItemId,
      //       valueID: spreadsheetIDs,
      //       valuation: (vehicleValuation) ? vehicleValuation : 0,
      //       capValues: (capValues) ? capValues : {},
      //       formComplete: 1,
      //       ...formData
      //     })
      //   }).then((response) => {
      //       console.log(response['data'])
      //       setCallingAPI(false)

      //       setFormStage((cur) => cur + 1);
      //   }).catch((response) => {
      //     //
      //   })
        
      //   break;
      default:
        setCallingAPI(false)
        
        setFormStage((cur) => cur + 1);
    }
  };
  const goPreviousStage = () => {
    setFormStage((cur) => cur - 1);
  };

  

  return (
    <>
    <Header />
    <div className="w-11/12 h-full mx-auto flex flex-col items-start justify-between pt-6 lg:pt-11 mb-auto">
      <form action="" className="w-full h-full flex flex-col justify-start">
        {formStage > 1 && formStage < 5 && <StageIndicator formStage={formStage} />}

        <div className={`relative lg:h-full z-10${ (formStage > 1) ? ' flex flex-col-reverse lg:flex-row' : '' }`}>

          {formStage === 0 && (
            <StageRegNumber callingAPI={callingAPI} regNumber={regNumber} setRegNumber={setRegNumber} setVehicleDetails={setVehicleDetails} completeFormStage={completeFormStage} />
          )}
          {formStage === 1 && (
              <StageIntro 
                formData={formData} 
                inputChanged={inputChanged} 
                vehicleDetails={vehicleDetails} 
                completeFormStage={completeFormStage} 
                vehicleDetailLoaded={vehicleDetailLoaded} 
                callingAPI={callingAPI} 
              />
          )}
          {formStage === 2 && (
              <Stage1 
                formData={formData} 
                inputChanged={inputChanged} 
                sliderChanged={sliderChanged} 
                mechanicalProblemsChanged={mechanicalProblemsChanged} 
                completeFormStage={completeFormStage} 
                goPreviousStage={goPreviousStage}
                callingAPI={callingAPI}
              />
          )}
          {formStage === 3 && (
              <Stage2
                completeFormStage={completeFormStage}
                goPreviousStage={goPreviousStage}
                vehicleValuation={vehicleValuation}
                vehicleValuationMessage={vehicleValuationMessage}
                regNumber={regNumber}
                formData={formData}
                setFormData={setFormData}
                inputChanged={inputChanged}
                callingAPI={callingAPI}
                mondayCrmItemId={mondayCrmItemId}
              />
          )}
          {/* {formStage === 4 && (
              <Stage3
                completeFormStage={completeFormStage}
                goPreviousStage={goPreviousStage}
                formData={formData}
                inputChanged={inputChanged} 
                callingAPI={callingAPI}
              />
              
          )} */}
          {formStage === 4 && (
              <Stage4
                completeFormStage={completeFormStage}
                goPreviousStage={goPreviousStage}
                formData={formData}
                callingAPI={callingAPI}
              />
              
          )}
          {
            vehicleDetails && formStage > 1 && (
              <QuoteInfo1 
                formStepTitles={formStepTitles} 
                formStage={formStage} 
                vehicleDetails={vehicleDetails} 
                formData={formData} 
                vehicleValuation={vehicleValuation} 
                vehicleValuationMessage={vehicleValuationMessage} 
                />
            )
          }
        
        </div>
      </form>
    </div>
  </>
  );
};

export default withRouter(FormMaster);
// {/* <QuoteInfo2 /> */}
// {/* <QuoteInfo3 /> */}
// export default FormMaster;
