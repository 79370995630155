import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import axios from "axios"

import ContactLink from "../global/ContactLink";

const title = "Your details";

const container = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      ease: [0.43, 0.13, 0.23, 0.96],
      duration: 0.8,
      staggerChildren: 0.15,
      delayChildren: 0,
    },
  },
};

const item = {
  hidden: {
    y: 30,
    opacity: 0,
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.43, 0.13, 0.23, 0.96],
      duration: 0.6,
    },
  },
};

const Stage2 = ({ completeFormStage, goPreviousStage, formData, setFormData, inputChanged, regNumber, vehicleValuation, callingAPI, mondayCrmItemId }) => {
  useEffect(() => {
    
    
    console.log(formData)


    var urlencoded = new URLSearchParams();
        urlencoded.append("id", mondayCrmItemId);
        urlencoded.append("vrm", formData.vrm);
        urlencoded.append("make", (formData['vehicleDetails']) ? formData['vehicleDetails']['make'] : "");
        urlencoded.append("description", (formData['vehicleDetails']) ? `${formData['vehicleDetails']['model']} ${formData['vehicleDetails']['description']}` : '');

    axios({
      method: 'post',
      url: "/.netlify/functions/mot-history",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: urlencoded,
    }).then((response) => {
      

    }).catch((err) => {
      console.log(err)
    })

  }, []);

  return (
    <>
      <motion.article
        variants={container}
        initial="hidden"
        animate="show"
        className="relative lg:h-full lg:w-6/12 pt-10 lg:pt-14 pb-12 flex flex-col justify-between z-10"
      >
        {/* {callingAPI && (
            <div className="loading"><div className="loading-indicator"><div></div><div></div><div></div><div></div></div></div>
          )} */}
        <div>
          <motion.h3 variants={item} className="font-body text-md leading-none hidden lg:block">
            {title}
          </motion.h3>
          <motion.div
            variants={item}
            className="max-w-3xl grid grid-cols-1 lg:grid-cols-3 gap-3 lg:gap-5 pt-7"
          >
            <div className="col-span-4 lg:col-span-3">
              <label htmlFor="name" className="p-3 font-body text-base text-black text-opacity-50 leading-none w-full block">Name</label>
              <input
                type="text"
                placeholder="John Smith"
                id="name"
                name="name"
                onChange={inputChanged} 
                value={formData.name}
                className="border-blue border focus:outline-none focus:ring-0 w-full h-14 rounded-md px-5 text-base font-body"
              />
            </div>

            <div className="col-span-4 lg:col-span-2">
              <label htmlFor="mobile" className="p-3 font-body text-base text-black text-opacity-50 leading-none w-full block">Email address</label>
              <input
                type="email"
                placeholder="email@example.com"
                id="email"
                name="email"
                onChange={inputChanged} 
                value={formData.email}
                className="border-blue border focus:outline-none focus:ring-0 w-full h-14 rounded-md px-5 text-base font-body"
              />
            </div>

            <div className="col-span-4 lg:col-span-1">
              <label htmlFor="postcode" className="p-3 font-body text-base text-black text-opacity-50 leading-none w-full block">Postcode</label>
              <input
                type="text"
                placeholder="A12 3BC"
                id="postcode"
                name="postcode"
                onChange={inputChanged} 
                value={formData.postcode}
                className="border-blue border focus:outline-none focus:ring-0 w-full h-14 rounded-md px-5 text-base font-body"
              />
            </div>

            <div className="col-span-4 lg:col-span-3 mb-10">
              <label htmlFor="message" className="p-3 font-body text-base text-black text-opacity-50 leading-none w-full block">Message</label>
              <textarea
                id="message"
                name="message"
                placeholder="Any further info about the van or collection?"
                cols="30"
                rows="10"
                value={formData.message}
                onChange={inputChanged}
                className="border-blue border outline-none focus:outline-none focus:ring-0 w-full h-36 rounded-md px-5 py-5 resize-none text-base font-body leading-none lg:col-span-3"
              ></textarea>
            </div>
            
            <div className="flex justify-between col-span-4">
              <button
                disabled={callingAPI}
                onClick={goPreviousStage}
                className="bg-teal w-3/12 h-14 rounded-md text-base font-body text-white mr-2 lg:mr-12"
              >
                Previous<span className="hidden md:inline"> step</span>
              </button>
              <button
                disabled={callingAPI}
                onClick={completeFormStage}
                className="bg-blue w-9/12 h-14 rounded-md text-base font-body lg:col-span-2"
              >
                <span className="font-bold">Next</span>&nbsp;-&nbsp;Quote details
              </button>
            </div>
            
          </motion.div>
        </div>
        <motion.div variants={item}>
          <ContactLink />
        </motion.div>
      </motion.article>
    </>
  );
};

export default Stage2;
