import { React, useRef, useState } from "react";
import { motion } from "framer-motion";
// Components
import Testimonial from "../global/Testimonial";

// Variables
const title = "Let's sell your van";
const desc = "Where should we send a copy of your quote?";

const container = {
  hidden: {
    opacity: "0",
  },
  show: {
    opacity: "1",
    transition: {
      ease: [0.43, 0.13, 0.23, 0.96],
      duration: 0.8,
      staggerChildren: 0.15,
      delayChildren: 0,
    },
  },
};

const item = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.43, 0.13, 0.23, 0.96],
      duration: 0.6,
    },
  },
};

const StageIntro = ({ formData, inputChanged, completeFormStage, vehicleDetails, vehicleDetailLoaded, callingAPI }) => {
  const phoneNumberField = useRef(null)
  const [validationMessage, setValidationMessage] = useState("");

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }

  const validateFields = (e) => {
    let pattern = new RegExp(/^[0-9\b]+$/);
    if (phoneNumberField.current.value.length < 8 || !pattern.test( phoneNumberField.current.value )) {
      setValidationMessage("Please enter a valid UK mobile number")
      return
    }

    completeFormStage(e)

  }
  return (
    <>
      <div className="w-full relative h-full flex flex-col items-center justify-between xl:pt-8 xxl:pt-8 lg:pb-4 xxl:pb-6">

          {/* {callingAPI && (
            <div className="loading"><div className="loading-indicator"><div></div><div></div><div></div><div></div></div></div>
          )} */}

        {
          (vehicleDetailLoaded) ?
            (
              <motion.div
                variants={container}
                initial="hidden"
                animate="show"
                className="flex flex-col items-center justify-center"
              >
                <motion.h1 variants={item} className="text-lg lg:text-xxl font-sans text-center">
                  Let's sell your {(vehicleDetails.success) ? Capitalize(vehicleDetails.make) : 'van'}
                </motion.h1>
                <motion.p
                  variants={item}
                  className="font-body text-base lg:text-sm mt-7 text-center"
                >
                  We're doing to ask for some details to get a quote for selling your {(vehicleDetails.success) ? Capitalize(vehicleDetails.make) : 'vehicle'}.<br/><br/>We can organise a collection as well as give an approximate valuation for your vehicle.<br/>Firstly, where should we send a copy of your quote?
                </motion.p>
                <div className="flex flex-col items-center mt-12 mb-12 lg:mb-3">
                  <motion.input
                    ref={phoneNumberField}
                    variants={item}
                    type="phone"
                    id="phone"
                    name="phone"
                    placeholder="Enter your phone number here"
                    className="border-blue border focus:outline-none focus:ring-0 w-80 h-14 rounded-md px-5 text-base font-body"
                    required
                    pattern="^(?:0|\(?\+33\)?\s?|0033\s?)[1-79](?:[\.\-\s]?\d\d){4}$"
                    onChange={inputChanged} 
                    value={formData.phone}
                  />
                  <motion.button
                    disabled={callingAPI}
                    variants={item}
                    type="button"
                    className="bg-blue w-80 h-14 rounded-md mt-5 text-base font-body"
                    onClick={validateFields}
                  >
                    <span className="font-bold">Next</span> - Van details
                  </motion.button>
                  { (validationMessage) ?
                    <p className="font-body text-base lg:text-sm mt-7 text-center text-orange">{ validationMessage }</p>
                  : ''}
                </div>
              </motion.div>
            )
          : ''
        }
        
        
      </div>
    </>
  );
};

export default StageIntro;
// Parent div element original padding ** DO NOT REMOVE PLEASE **
// pt-16 xxl:pt-28 lg:pb-4 xxl:pb-6
