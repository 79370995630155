import React from "react";
import { motion } from "framer-motion";
//Components

// Variants

const userQuote = "Brilliant, painless, effortless, hassle free.";
const userName = "Steven Adams";

const container = {
  hidden: {
    y: 200,
  },
  show: {
    y: 0,
    transition: {
      ease: [0.43, 0.13, 0.23, 0.96],
      duration: 0.8,
      staggerChildren: 0.25,
      delayChildren: 0.8,
    },
  },
};

const item = {
  hidden: {
    opacity: 0,
    y: -60,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.43, 0.13, 0.23, 0.96],
      duration: 1,
    },
  },
};

function Testimonial() {
  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="show"
      className="bg-blue-dark flex justify-center overflow-hidden px-14 py-9 lg:w-11/12 lg:mx-auto lg:rounded-md"
    >
      <div className="grid grid-cols-footer gap-7">
        <motion.div variants={item} className="flex items-center">
          <img
            src="https://source.unsplash.com/X6Uj51n5CE8"
            alt="Profile of reviewer"
            className="w-14 h-14 object-cover overflow-hidden rounded-full"
          />
        </motion.div>

        <motion.div variants={item} className="font-body text-white">
          <p className="text-sm lg:text-md mb-3.5 xl:mb-1">{userQuote}</p>
          <p>
            <span className="text-blue mr-2">{userName}</span>
            <span>Google Review</span>
          </p>
        </motion.div>
      </div>
      <motion.div variants={item} className="hidden lg:flex lg:items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="212.855"
          height="40.428"
          viewBox="0 0 212.855 40.428"
        >
          <g id="start_rating" transform="translate(17239.855 14594)">
            <g id="star_no_5" transform="translate(-17067.428 -14594)">
              <path
                id="Path_7106"
                data-name="Path 7106"
                d="M0,0H40.427V40.427H0Z"
                fill="none"
              />
              <path
                id="Path_7107"
                data-name="Path 7107"
                d="M18.845,27.722l10.41,6.283L26.492,22.163l9.2-7.968L23.578,13.168,18.845,2,14.111,13.168,2,14.2l9.2,7.968L8.435,34.005Z"
                transform="translate(1.369 1.369)"
                fill="#444A54"
              />
            </g>
            <g id="star_no_4" transform="translate(-17107.855 -14594)">
              <path
                id="Path_7106-2"
                data-name="Path 7106"
                d="M0,0H40.427V40.427H0Z"
                fill="none"
              />
              <path
                id="Path_7107-2"
                data-name="Path 7107"
                d="M18.845,27.722l10.41,6.283L26.492,22.163l9.2-7.968L23.578,13.168,18.845,2,14.111,13.168,2,14.2l9.2,7.968L8.435,34.005Z"
                transform="translate(1.369 1.369)"
                fill="#72d7f4"
              />
            </g>
            <g id="star_no_3" transform="translate(-17151.855 -14594)">
              <path
                id="Path_7106-3"
                data-name="Path 7106"
                d="M0,0H40.427V40.427H0Z"
                fill="none"
              />
              <path
                id="Path_7107-3"
                data-name="Path 7107"
                d="M18.845,27.722l10.41,6.283L26.492,22.163l9.2-7.968L23.578,13.168,18.845,2,14.111,13.168,2,14.2l9.2,7.968L8.435,34.005Z"
                transform="translate(1.369 1.369)"
                fill="#72d7f4"
              />
            </g>
            <g id="star_no_2" transform="translate(-17195.855 -14594)">
              <path
                id="Path_7106-4"
                data-name="Path 7106"
                d="M0,0H40.427V40.427H0Z"
                fill="none"
              />
              <path
                id="Path_7107-4"
                data-name="Path 7107"
                d="M18.845,27.722l10.41,6.283L26.492,22.163l9.2-7.968L23.578,13.168,18.845,2,14.111,13.168,2,14.2l9.2,7.968L8.435,34.005Z"
                transform="translate(1.369 1.369)"
                fill="#72d7f4"
              />
            </g>
            <g id="star_no_1" transform="translate(-17239.855 -14594)">
              <path
                id="Path_7106-5"
                data-name="Path 7106"
                d="M0,0H40.427V40.427H0Z"
                fill="none"
              />
              <path
                id="Path_7107-5"
                data-name="Path 7107"
                d="M18.845,27.722l10.41,6.283L26.492,22.163l9.2-7.968L23.578,13.168,18.845,2,14.111,13.168,2,14.2l9.2,7.968L8.435,34.005Z"
                transform="translate(1.369 1.369)"
                fill="#72d7f4"
              />
            </g>
          </g>
        </svg>
      </motion.div>
    </motion.div>
  );
}

export default Testimonial;
