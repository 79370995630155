import React from "react";
import { motion } from "framer-motion";
import axios from "axios"
import { useHistory, useLocation } from "react-router-dom"

// Components
import Testimonial from "../global/Testimonial";
// import GetVehicleDetailsFromReg from "../global/Testimonial";

// Variables
const title = "Enter your reg";
const desc = "Get a free valuation now.";

const container = {
  hidden: {
    opacity: "0",
  },
  show: {
    opacity: "1",
    transition: {
      ease: [0.43, 0.13, 0.23, 0.96],
      duration: 0.8,
      staggerChildren: 0.15,
      delayChildren: 0,
    },
  },
};

const item = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.43, 0.13, 0.23, 0.96],
      duration: 0.6,
    },
  },
};



const StageRegNumber = ({ completeFormStage, setVehicleDetails, callingAPI, setRegNumber }) => {
  const SITE_KEY = "6Ld-06QdAAAAAA4keEOZtn-ftbFPZPwH0ee6ePw1";

  // Input Change Handling
  const [inputs, setInputs] = React.useState({
    reg: "",
  });

  let history = useHistory();
  const searchParams = useLocation().search;

  const handleOnChange = event => {
    event.persist();
    setInputs(prev => ({
      ...prev,
      [event.target.id]: event.target.value
    }));
  };

  const triggerStageComplete = event => {

    if(!inputs.reg) {
      alert("Please enter reg number")
      return
    }

    let inputRegFormatted = inputs.reg.replace(/\s/g, '')

    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {

        axios.post('/.netlify/functions/vehicle-details?reg='+inputRegFormatted).then((response) => {
          let vehicleDetails = response.data
          if(vehicleDetails['success']) {
            setVehicleDetails(vehicleDetails)
          } else {
            setRegNumber(inputRegFormatted)
          }
          
          completeFormStage(inputRegFormatted)
          
          if(searchParams) {
            history.push("/" + searchParams + "&reg="+inputRegFormatted);
          } else {
            history.push("/?reg="+inputRegFormatted);
          }
    
    
        }).catch((err) => {
          console.log(err)
    
          completeFormStage(inputRegFormatted)
          
        });

      });
    });
    
    
    
  }


  return (
    <>
      <div className="w-full h-full flex flex-col items-center justify-between pb-32 xl:pt-8 xxl:pt-8 lg:pb-4 xxl:pb-6">
        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
          className="flex flex-col items-center justify-center"
        >
          <motion.h1 variants={item} className="text-lg lg:text-xxl font-sans">
            {title}
          </motion.h1>
          <motion.p
            variants={item}
            className="font-body text-base lg:text-sm mt-7"
          >
            {desc}
          </motion.p>
          <div className="flex flex-col items-center mt-12 mb-12 lg:mb-3">
            <motion.div
              variants={item}
            >
              <input
                type="text"
                id="reg"
                name="reg"
                value={inputs.reg}
                onChange={handleOnChange}
                placeholder="Enter your vehicle reg"
                className="border-blue border focus:outline-none focus:ring-0 w-80 h-14 rounded-md px-5 text-base font-body"
                required
              />
            </motion.div>
            
            <motion.button
              variants={item}
              type="button"
              className="bg-blue w-80 h-14 rounded-md mt-5 text-base font-body"
              onClick={triggerStageComplete}
            >
              <span className="font-bold">Next</span> - Van details
            </motion.button>
          </div>
        </motion.div>
        {/* <Testimonial /> */}
      </div>
    </>
  );
};

export default StageRegNumber;
