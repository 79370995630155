import React from "react";

const ContactLink = () => {
  return (
    <>
      <div className="mt-11">
        <p className="text-base font-body">
          Prefer to call us or have any questions?{" "}
          <a href="/" className="underline text-orange">
            Contact us
          </a>
        </p>
      </div>
    </>
  );
};

export default ContactLink;
