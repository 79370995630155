import React from "react";

function TestHeader() {
  return (
    <header className="w-full pt-5 lg:pt-8 px-6 lg:px-4 xl:px-8">
      <nav className="flex items-center justify-between">
        <img style={{
            maxWidth: "11rem",
            minWidth: "0",

        }} src="https://wbbv.persona.studio/wp-content/themes/wbbv/dist/img/logo.gif" />
        <a href="/valuation-test" className="ml-auto px-2 hover:underline">Valuation Test</a>|
        <a href="/valuation-modifiers" className="px-2 hover:underline">Modifier values</a>|
        <a href="/van-type-modifiers" className="px-2 hover:underline">Van type values</a>|
        <a href="/van-model-modifiers" className="px-2 hover:underline">Van model values</a>
        {/* <a href="/">
          <div className="bg-yellow px-14 py-4 rounded-md font-body">
            Go back
          </div>
        </a> */}
      </nav>
    </header>
  );
}

export default TestHeader;
